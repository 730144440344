import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SectorsComponent } from 'src/app/components/view/sectors/sectors.component';
import { ActualityDetailComponent } from './components/view/actualities/actuality-detail/actuality-detail.component';
import { ForumDetailComponent } from './components/view/forums/forum-detail/forum-detail.component';
import { PartnerContactComponent } from './components/view/partner-contact/partner-contact.component';
import { PodcastDetailComponent } from './components/view/podcasts/podcast-detail/podcast-detail.component';
import { StudyDetailComponent } from './components/view/studies/study-detail/study-detail.component';
import { TrainingsComponent } from './components/view/training-home/trainings/trainings.component';
import { VideoDetailComponent } from './components/view/videos/video-detail/video-detail.component';
import { DeactivateLoginGuard } from './shared/guards/deactivate-login.guard';
import { UnderMaintenanceService } from './shared/guards/under-maintenance-auth-guard';

const routes: Routes = [
  // Pages Principales *********/
  {
    path: '', loadChildren: () => import(
      './components/view/homepage/homepage.module'
    ).then(
      m => m.HomePageModule
    ),
    canActivate: [UnderMaintenanceService]
  },
  {
    path: 'decouvrir-la-deeptech/.',
    loadChildren: () =>
      import('./components/view/about-deeptech/about-deeptech.module').then(
        (m) => m.AboutDeeptechModule
      ),
  },

  {
    path: 'contact.html',
    loadChildren: () =>
      import('./components/view/contact/contact.module').then(
        m => m.ContactModule
      ),
  },

  {
    path: 'partner-contact.html',
    loadChildren: () =>
      import('./components/view/partner-contact/partner-contact.module').then(
        m => m.PartnerContactModule
      ),
  },

  {
    path: 'qui-sommes-nous.html',
    loadChildren: () =>
      import('./components/view/about-us/about-us.module').then(
        m => m.AboutUsModule
      ),
  },

  {
    path: 'conditions-generales-utilisation.html',
    loadChildren: () =>
      import('./components/view/cgu/cgu.module').then(
        m => m.CguModule
      ),
  },

  {
    path: 'international-page/.',
    loadChildren: () =>
      import('./components/view/international-page/international-page.module').then(
        (m) => m.InternationalPageModule
      ),
  },
  /* {
     path: 'observatoire-startups-deeptech/.',
     loadChildren: () =>
       import(
         './components/view/observatory-startups/observatory-startups.module'
       ).then((m) => m.ObservatoryStartupsModule),
   },
   */
  {
    path: 'euroquity/.',
    loadChildren: () =>
      import('./components/view/euroquity/euroquity.module').then(
        (m) => m.EuroquityModule
      ),
  },

  {
    path: 'login/.',
    loadChildren: () =>
      import('./components/view/login/login.module').then(
        (m) => m.LoginModule
      ),
    canDeactivate: [DeactivateLoginGuard]
  },
  {
    path: 'page-401/.',
    loadChildren: () =>
      import('./components/view/page401/page401.module').then(
        (m) => m.Page401Module
      ),
  },
  {
    path: 'page-404/.',
    loadChildren: () =>
      import('./components/view/page404/page404.module').then(
        (m) => m.Page404Module
      ),
  },
  {
    path: 'under-maintenance/.',
    loadChildren: () =>
      import('./components/view/under-maintenance/under-maintenance.module').then
        ((m) => m.UnderMaitenanceModule),
  },
  {
    path: 'actualites-ressources/.',
    loadChildren: () =>
      import(
        './components/view/actualities-resources/actualities-resources.module'
      ).then((m) => m.ActualitiesResoucesModule),
  },

  /**TANDEM MODULES */
  {
    path: 'tandem/.',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/home/job-offer-home.module').then(
          (m) => m.JobOfferHomeModule
        ),
  },
  {
    path: 'tandem/tous-les-projets.html',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/offers/job-offers.module').then(
          (m) => m.JobOffersModule
        ),
  },
  {
    path: 'tandem/:selectedOffre',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/offers-details/job-offer-detail.module').then(
          (m) => m.JobOfferDetailModule
        ),
  },



  {
    path: 'me-former/.',
    loadChildren: () =>
      import(
        './components/view/training-home/training-home.module').then(
          (m) => m.TrainingHomeModule
        ),
  },
  { path: 'toutes-les-formations/.', component: TrainingsComponent },

  {
    path: 'page-500/.',
    loadChildren: () =>
      import('./components/view/page500/page500.module').then(
        (m) => m.Page500Module
      ),
  },

  /*-----------------------------------------------*/
  /** Parent : creer-sa-startup */
  {
    path: 'envie-d-entreprendre/.',
    loadChildren: () => import('./components/view/desire-undertake/desire-undertake.module')
      .then(m => m.DesireUndertakeModule)

  },

  /** Childe de creer sa startup */
  {
    path: 'proposer-evenement-concours-appels-a-projets/.',
    loadChildren: () =>
      import(
        './components/view/events-contests-form/events-contests-form.module'
      ).then((m) => m.EventsContestsFormModule),
  },


  /*-----------------------------------------------*/
  /** Parent : creer-sa-startup */
  // { path: 'creer-sa-startup/.', component: CreateStartupsComponent },

  // TO REMOVE AFTER FIXING 404 ERROR PAGES
  { path: 'creer-sa-startup', redirectTo: '/page-404/.' },

  /** TO DO: REMOVE THESE AFTER CONFIRMATION */
  /*
  {
    path: 'creer-sa-startup/transfert-de-technologies.html',
    component: TransferTechnologiesComponent,
  },
  {
    path: 'creer-sa-startup/declarer-invention.html',
    component: DeclareInventionComponent,
  },
  {
    path: 'creer-sa-startup/protection-propriete-intellectuelle.html',
    component: ProtectInventionComponent,
  },
  {
    path: 'creer-sa-startup/resultat-de-recherche.html',
    component: ResearchResultComponent,
  },
  {
    path: 'creer-sa-startup/entreprendre-en-equipe.html',
    component: BuildTeamComponent,
  },
  {
    path: 'creer-sa-startup/acces-au-marche.html',
    component: MarketAccessComponent,
  },
  {
    path: 'creer-sa-startup/plan-de-financement.html',
    component: BuildBusinessPlanComponent,
  },
  {
    path: 'creer-sa-startup/financer-son-projet.html',
    component: FinanceProjectComponent,
  },
  {
    path: 'creer-sa-startup/accompagnement-startup.html',
    component: CoachEcosystemComponent,
  },
  */
  /*-------------------------------------------------------------------------------------------------- */
  /** Parent : communaute-startups-deeptech-france */
  {
    path: 'communaute-startups-deeptech-france/.',
    loadChildren: () => import('./components/view/memberships/memberships.module').then(m => m.MembershipsModule),
  },
  /*-------------------------------------------------------------------------------------------------- */


  // *Children : actualites-ressources-v2021 */

  {
    path: 'actualites/.', loadChildren: () =>
      import('./components/view/actualities/actualities.module')
        .then(m => m.ActualitiesModule)
  },
  {
    path: 'actualites/:selectedActualityTag/:selectedActuality',
    component: ActualityDetailComponent,
  },

  { path: 'breves/.', loadChildren: () => import('./components/view/briefs/briefs.module').then(m => m.BriefsModule) },

  { path: 'tribunes/.', loadChildren: () => import('./components/view/forums/forums.module').then(m => m.ForumsModule) },
  { path: 'tribunes/:selectedTag/:selectedForum', component: ForumDetailComponent },

  { path: 'etudes/.', loadChildren: () => import('./components/view/studies/studies.module').then(m => m.StudiesModule) },
  { path: 'etudes/:selectedTag/:selectedStudy', component: StudyDetailComponent },

  {
    path: 'podcasts/.', loadChildren: () =>
      import('./components/view/podcasts/podcasts.module').then(m => m.PodcastsModule)
  },
  { path: 'podcasts/:selectedTag/:selectedPodcast', component: PodcastDetailComponent },

  { path: 'videos/.', loadChildren: () => import('./components/view/videos/videos.module').then(m => m.VideosModule) },

  { path: 'videos/:selectedTag/:selectedVideo', component: VideoDetailComponent },

  /*-------------------------------------------------------------------------------------------------- */

  { path: 'euroquity/.', loadChildren: () => import('./components/view/euroquity/euroquity.module').then(m => m.EuroquityModule) },
  // { path: 'observatoire-startups-deeptech/.', component: ObservatoireStartupsComponent },
  { path: 'evenements/.', loadChildren: () => import('./components/view/events/events.module').then(m => m.EventsModule) },

  /*------------------------------------------------------------------------------------*/

  /**** Parent : ADVISOR  ************* */
  {
    path: 'devenir-advisor/.',
    loadChildren: () => import(
      './components/view/job-offers/advisor/advisor.module').then(
        (m) => m.AdvisorModule
      )
  },
  {
    path: 'devenir-advisor/tous-les-projets.html', loadChildren: () => import('./components/view/job-offers/advisor/offers/job-offers-advisor.module').then(
      m => m.JobOffersAdvisorModule
    ),
  },
  {
    path: 'devenir-advisor/:selectedOffer', loadChildren: () => import('./components/view/job-offers/advisor/offers-details/job-offer-detail-advisor.module').then(
      m => m.JobOfferDetailAdvisorModule
    )
  },
  /*------------------------------------------------------------------------------------*/
  /*------------------------------------------------------------------------------------*/


  /**** Parent : training OTT ************* */
  { path: 'catalogue-formations-ott/.', loadChildren: () => import('./components/view/training-ott/training-ott.module').then(m => m.TrainingOttModule) },

  /**** Parent : Accompaying space          
  { path: 'accompagnez-vos-startups/.', loadChildren: () => import('./components/view/companions/companions.module').then(m => m.CompanionsModule) },
 **** */
  {
    path: 'secteurs/:selectedSector',
    loadChildren: () =>
      import('./components/view/sectors/sectors.module').then(
        (m) => m.SectorsModule
      ),
  },

  /**** Partner-space **** */
  {
    path: 'mon-espace-partenaire/les-services.html?origin=intranet',
    redirectTo:'mon-espace-partenaire/les-services.html'
  },
  {
    path: 'mon-espace-partenaire/.?origin=intranet',
    redirectTo:'mon-espace-partenaire/.'
  },
  {
    path: 'mon-espace-partenaire',
    loadChildren: () =>
      import('./components/view/partner-space/partner-space.module').then(
        (m) => m.PartnerSpaceModule
      ),
  },

  {
    path: 'mon-espace-partenaire/silent-refresh.html',
    loadChildren: () =>
      import('./shared/silent-refresh/silent-refresh.module').then(
        m => m.SilentRefreshModule
      )
  },

  /**** Sectors new Paths **** */

  {
    path: 'agritech-foodtech/.', component: SectorsComponent,
  },
  {
    path: 'biotech/.', component: SectorsComponent,
  },
  {
    path: 'cybersecurite/.', component: SectorsComponent,
  },
  {
    path: 'energie/.', component: SectorsComponent,
  },
  {
    path: 'IA-software/.', component: SectorsComponent,
  },
  {
    path: 'industrie/.', component: SectorsComponent,
  },
  {
    path: 'medtech/.', component: SectorsComponent,
  },
  {
    path: 'mobilite-ville-durable/.', component: SectorsComponent,
  },
  {
    path: 'quantique/.', component: SectorsComponent,
  },
  {
    path: 'spacetech/.', component: SectorsComponent,
  },

  {
    path: 'partnerform/.', component: PartnerContactComponent
  },
  {
    path: 'proposer-evenement-concours-appels-a-projets.html',
    loadChildren: () =>
      import('./components/view/events-contests-form/events-contests-form.module').then(
        (m) => m.EventsContestsFormModule
      ),
  },

  /**Route contests : concours et appels a projets */
  { path: 'concours-appels-a-projets/.', loadChildren: () => import('./components/view/contests/contests.module').then(m => m.ContestsModule) },

  /**Route Glossary */
  {
    path: 'envie-d-entreprendre/glossaire/.',
    loadChildren: () =>
      import('./components/view/glossary/glossary.module').then(
        (m) => m.GlossaryModule
      )
  },

  {
    path: 'envie-d-entreprendre/:selectedCategory/:selectedFaqDetail',
    loadChildren: () =>
      import('./components/view/faq-details/faq-details.module').then(
        (m) => m.FaqDetailsModule
      ),
  },
  {
    path: 'regions/:regionId',
    loadChildren: () =>
      import('./components/view/regions/region.module').then(
        (m) => m.RegionModule
      ),
  },
  { path: 'publish-offer.html', loadChildren: () => import('./components/view/publish-offer/publish-offer.module').then(m => m.PublishOfferModule) },
  {
    path: 'atlas-de-la-deeptech/.',
    loadChildren: () => import(
      './components/view/deeptech-france/deeptech-france.module').then(
        (m) => m.DeeptechFranceModule
      )
  },

  /*------------------------------------------------------------------------------------*/
  //formulaire de demande d'accès 
  {
    path: 'demande-acces-form/.',
    loadChildren: () => import('./components/view/access-request-form/access-request-form.module').then
      (m => m.AccessRequestFormModule)
  },

  // Lien temporaire pour tester la partie INT
  { path: 'search-results.html', loadChildren: () => import('./components/view/search-results/search-results.module').then(m => m.SearchResultsModule) },

  

  /*------------------------------------------------------------------------------------*/
  { path: 'delete-account/.', loadChildren: () => import('./components/view/account-deleted/account-deleted.module').then(m => m.AccountDeletedModule) },
  { path: 'publish-offer.html', loadChildren: () => import('./components/view/publish-offer/publish-offer.module').then(m => m.PublishOfferModule) },
  { path: '**', redirectTo: '/page-404/.' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledNonBlocking'
      // relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
